import './App.css';
import Sidepanel from'./components/sidepanel'
import Intro from './components/intro';
import Ourbrand from'./components/ourbrand'
import Ourservices from './components/ourservices'
import Contact from './components/contact'
function App() {
  return (
    <div className="App">
     <Sidepanel/>
     <Intro/>
     <Ourbrand/>
     <Ourservices/>
     <Contact />
    </div>
  );
}


export default App;
