import React from 'react'

const Intro = () => {
  return (
    <div className='intro'>
      <div className='circle'></div>
      <div className='semicircle'></div>
      <div className='title'>
          <h1 className='title-1'>TWYN</h1>
          <h1 className='title-2'>FISH</h1>
      </div>
      
      </div>
  )
}

export default Intro