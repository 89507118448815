import React from 'react'

const Sidepanel = () => {
  return (
    <div className='panel'>
     <div className='Sidepanel'>
        <div className='nav-1'>Home</div>
        <div className='nav-2'>Our Brand</div>
        <div className='nav-3'>Our services</div>
        <div className='nav-4'>About Us</div>
        <div className='nav-5'>Get in Touch</div>
     </div>
     <div classname="trackingline"></div>
    </div>
  )
}


export default Sidepanel